import React, { useState } from "react";

const Scheduler = ({ schedules, onChange, timesPerDay = 1 }) => {
  let index = 0;

  const Inputs = [];
  while (index <= schedules.length) {
    const arrayIndex = index;
    let timeValue;
    if (schedules[arrayIndex]) {
      if (schedules[arrayIndex].indexOf(":") > -1) {
        timeValue = schedules[arrayIndex];
      } else {
        timeValue =
          schedules[arrayIndex].substring(0, 2) +
          ":" +
          schedules[arrayIndex].substring(2);
      }
    }
    console.log(timeValue)
    // if(timeValue != null || index == schedules.length){

      Inputs.push(
        <input
        type="time"
        value={timeValue}
        id={"schedule-" + arrayIndex}
        key={arrayIndex}
        onChange={(e) => onChange(e, arrayIndex)}
        name={`schedule[${arrayIndex}]`}
        />
        );
      // }
    // console.log(timesPerDay)

    index++;
  }

  return (
    <>
      <h1>Schedule</h1>

      {Inputs}
    </>
  );
};

export default Scheduler;
