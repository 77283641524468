import React from "react";
import styled from "styled-components";

import defaultProfile from "../../assets/img/default_profile.png";

const ProfilePicture = ({ img, onClick }) => {

  const image =
    img !==
      "http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png" &&
    img !== ""
      ? img
      : defaultProfile;
  return (
    <ProfilePictureWrapper className="profile-picture" onClick={onClick}>
      <img src={image} />
    </ProfilePictureWrapper>
  );
};

export default ProfilePicture;

const ProfilePictureWrapper = styled.div`
  height: auto;
  max-width: 100%;
  width: 100%;
  margin: 0.5rem;
  border-radius: 50%;
  border: 1px solid #fff;
  overflow: hidden;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
`;
