import React, { useState, useEffect, useContext } from "react";
import UserCard from "../components/users/UserCard";
import styled from "styled-components";

import UserContext from "../UserContext";

const moment = require('moment-timezone');

const Threads = ({ userProp }) => {
  const user = useContext(UserContext);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [analytics, setAnalytics] = useState(user.analytics);


  const handleChange = (e) => {
    let name = e.target.name;
   


    setAnalytics({...analytics, [name]: e.target.value})
      console.log(analytics)
      

  };

  const handleScheduleChange = (e, index) => {
    let analyticsObj= {...analytics};

    console.log(index);

    analyticsObj.schedule[index] = e.target.value;


    setAnalytics(analyticsObj)
    console.log(analyticsObj);

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({submitting: analytics});

    analytics.schedule.sort();



    fetch(process.env.REACT_APP_API_URL + "user/update", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(analytics),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        setAnalytics({...analytics})

        window.location = "/";

        console.log(responseJson)
      })
      .catch((error) => {
        return { error: error };
      });
  };

  return (
    <Main>
        <DangerZone>
          <h1>Pinned Threads Coming Soon!</h1>
        </DangerZone>
    </Main>
  );
};

export default Threads;

const Main = styled.main`
  max-width: 800px;

  display: grid;
  grid-template-columns: 1fr ;
  align-items: center;
  grid-gap: 2em;
  margin: auto;
`;

const LoggedIn = styled.div`
  width: 100%;
  margin: 1em auto;
  text-align: center;
  display: block;

  position: sticky;
  top: 1em;

  h3 {
    font-weight: 400;
    font-style: italic;
  }

  a {
    color: #999;
  }
`;

const AnalyticsForm = styled.form`
  display: flex;
  flex-direction: column;

  input, select {
    margin: 0.5em 0;
    font-size: 1.5em;
    width: 100%;
  }
  label {
    margin: 1em 0;
  }

  button[type="submit"] {
    font-size: 1.25em;
    padding: 0.5em;
    background: var(--tweetColor);
    border: none;
    box-shadow: var(--boxShadow);
    color: #fff;
    border-radius: var(--borderRadius);
    margin-bottom: 2em;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    display: inline-block;
    width: 50%;
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: var(--boxShadow);

    transition: all 0.1s ease-in-out;

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }

  .switch-field label:hover {
    cursor: pointer;
  }

  .switch-field input:checked + label {
    background-color: var(--tweetColor);
    color: #fff;
    font-weight: 900;
  }
`;

const DangerZone = styled.div`
  border: 6px solid var(--red);
  width: 100%;
  margin: 1em auto;
  padding: 1em;
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);

  h1,
  h4 {
    color: var(--red);
  }

  & *:last-child {
    margin-bottom: 0;
  }
`;
