import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import Loader from "./common/loader";
import Tweet from "./common/Tweet";
import UserContext from "../UserContext";
import TweetContext from "../TweetContext";
import { device } from "../utils/breakpoints";


const DraftTweets = () => {
  const user = useContext(UserContext);
  const [state, dispatch] = useContext(TweetContext);
  const [DraftTweets, setDraftTweets] = useState([]);

  useEffect(() => {
 
   
    fetch(process.env.REACT_APP_API_URL + "tweet/drafts", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((response) => {
        
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        dispatch({ type: "success" });
        // console.log({drafts: responseJson});
        setDraftTweets(responseJson);
      })
      .catch((error) => {
        return { error: error };
      });

  }, [state.status, state.DraftTweets]);

  const tweets =
      DraftTweets.length > 0 ? (
        DraftTweets.map((tweet,i) => (
          <Tweet
          tweetId={tweet._id}
          draft
          tweet={tweet}
          date={user.nextTweet ? user.nextTweet[i] : null}
          key={tweet._id}
          user={user}
        />
        ))
      ) : (
        (state.status === "loading" || state.status === null ? <NothingScheduled>Loading.</NothingScheduled> : <NothingScheduled>No Drafts</NothingScheduled>)
        
      )
    
    
       
  return DraftTweets.length > 0 ? (
    <Intro>
      <h1>Drafts
      <Loader loading={(state.status === 'loading')} onClick={()=>{dispatch({ type: "loading" });}} />      </h1>
      <TweetList>{tweets}</TweetList>
    </Intro>
  ) : ('');
};

export default DraftTweets;

const Intro = styled.section`
  padding: 2rem 0;
  margin: 2rem;
  width: 100%;
  position: relative;

  .loader{
    font-size: 1rem;
    width: auto;
    display: inline-flex;
    margin: 0 0.5rem;
    color: #fff;
  }
`;

const NothingScheduled = styled.h2`
  font-weight: 400;
  text-align: center;
  margin: 1em;
  color: #fff;
  font-size: 1.25rem;

  @media ${device.laptop}{
  margin: 3em;

  }
`;

const TweetList = styled.section`
  border: 1px solid var(--gray);
  margin: 1em auto;
  max-width: 800px;
`;
