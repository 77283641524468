import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import Loader from "./common/loader";
import Tweet from "./common/Tweet";
import UserContext from "../UserContext";
import TweetContext from "../TweetContext";
import { device } from "../utils/breakpoints";

const RecentTweets = ({
  tweetBoxRef,
  setTweetBoxText,
  setTweetBoxReplyToId,
}) => {
  const [recentTweets, setRecentTweets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setError] = useState(true);

  const [state, dispatch] = useContext(TweetContext);

  const user = useContext(UserContext);

  useEffect(() => {
    let isCancelled = false;

    // console.log({stateStatus: state.status});

    fetch(process.env.REACT_APP_API_URL + "tweet/timeline", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        dispatch({ type: "success" });

        setRecentTweets(responseJson);

        // console.log(responseJson);
      })
      .catch((error) => {
        if (!isCancelled) {
          setError("Failed to authenticate user");
        }
      });

    return function cleanup() {
      isCancelled = true;
    };
  }, [state.status]);

  const findUser = (user_id) => {
    let user = recentTweets.includes.users.filter((user) => {
      return user_id === user.id;
    });
    return user[0];
  };

  const tweets = recentTweets.data?.length ? (
    recentTweets.data.map((tweet) => (
      <Tweet
        setTweetBoxReplyToId={setTweetBoxReplyToId}
        setTweetBoxText={setTweetBoxText}
        tweet={tweet}
        tweetId={tweet.id}
        tweetUser={findUser(tweet.author_id)}
        key={tweet.id}
        user={user}
        tweetBoxRef={tweetBoxRef}
      />
    ))
  ) : state.status === "loading" || state.status === null ? (
    <NothingScheduled>Oh twitter gods, grant me my tweets.</NothingScheduled>
  ) : (
    ""
  );

  return (
    <Intro>
      <h1>
        Recent Tweets
        <Loader
          loading={state.status === "loading"}
          onClick={() => {
            dispatch({ type: "loading" });
          }}
        />
      </h1>

      <TweetList>{tweets}</TweetList>
    </Intro>
  );
};

export default RecentTweets;

const Intro = styled.section`
  padding: 2rem 0;
  margin: 2rem;
  width: 100%;
  position: relative;
  .loader {
    font-size: 1rem;
    width: auto;
    display: inline-flex;
    margin: 0 0.5rem;
    color: #fff;
  }
`;

const TweetList = styled.section`
  border: 1px solid var(--gray);
  margin: 1em auto;
  max-width: 800px;
`;

const NothingScheduled = styled.h2`
  font-weight: 400;
  text-align: center;
  margin: 1em;
  color: #fff;
  font-size: 1.25rem;

  @media ${device.laptop}{
  margin: 3em;

  }
`;
