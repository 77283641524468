import React, { useState, useEffect } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import queryString from 'query-string';
import "./App.css";
import Sidebar from "./components/common/Sidebar";
import styled from "styled-components";
import TwitterSignIn from "./components/auth/TwitterSignIn";
import RecentTweets from "./components/RecentTweets";


import ScheduledTweets from "./components/ScheduledTweets";
import { device } from "./utils/breakpoints";
import Settings from "./pages/settings";

import { UserProvider } from "./UserContext";
import { TweetProvider } from "./TweetContext";
import DraftTweets from "./components/DraftTweets";
import Analytics from "./pages/analytics";
import Edit from "./pages/edit"; 
import Footer from "./components/common/footer";

import './components/FontAwesome';
import Threads from "./pages/threads";
import TweetBox from "./components/TweetBox";
import LoaderPage from "./components/common/loader-page";

const App = () => {
  const [user, setUser] = useState({});
  const [authenticated, setAuthenticated] = useState(null);
  const [tweetBoxText, setTweetBoxText] = useState(['']);
  const [tweetBoxReplyToId, setTweetBoxReplyToId] = useState();

  const tweetBoxRef = React.createRef([]);


  useEffect(() => {
    // console.log(process.env);
    // Fetch does not send cookies. So you should add credentials: 'include'

    fetch(process.env.REACT_APP_API_URL + "auth/login/success", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    }).then((response)=>{
      console.log(authenticated);
      setAuthenticated(null);
      return response
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        setUser(responseJson.user);
        setAuthenticated(true);
      })
      .catch((error) => {
        setUser("Failed to authenticate user");
        setAuthenticated(false);
      });

      
      
  }, []);
  var params = useLocation();
  params = queryString.parse(params.search);
  console.log(params);
  let content = authenticated === true ? (
    <Switch>
      <Route path="/" exact>
        {authenticated && (
          <>
            <Intro>
              <h1>Hey There, @{user.screen_name}!</h1>
            </Intro>
            <TweetBox tweetBoxReplyToId={tweetBoxReplyToId} setTweetBoxReplyToId={setTweetBoxReplyToId}  tweetBoxText={tweetBoxText} setTweetBoxText={setTweetBoxText} tweetBoxRef={tweetBoxRef} />
            <ScheduledTweets setTweetBoxReplyToId={setTweetBoxReplyToId} tweetBoxRef={tweetBoxRef} />
            <DraftTweets setTweetBoxReplyToId={setTweetBoxReplyToId} tweetBoxRef={tweetBoxRef} />
            <RecentTweets setTweetBoxReplyToId={setTweetBoxReplyToId} setTweetBoxText={setTweetBoxText} tweetBoxRef={tweetBoxRef} />
          </>
        )}
      </Route>

      <Route path="/settings">
        <Intro>
          <Settings user={user} />
        </Intro>
      </Route>

      <Route path="/edit/:id">
        <Intro>
          <Edit user={user} tweetBoxReplyToId={tweetBoxReplyToId} setTweetBoxReplyToId={setTweetBoxReplyToId}  tweetBoxText={tweetBoxText} setTweetBoxText={setTweetBoxText} tweetBoxRef={tweetBoxRef} />
        </Intro>
      </Route>
      <Route path="/analytics">
        <Intro>
          <Analytics user={user} />
        </Intro>
      </Route>
      <Route path="/threads">
        <Intro>
          <Threads user={user} />
        </Intro>
      </Route>
    </Switch>
  ) : (
    authenticated === false && authenticated !== null ? <TwitterSignIn notYet={('notyet' in params) ? true : false} /> : <LoaderPage/>
  );

  const _handleNotAuthenticated = () => {
    // this.setState({ authenticated: false });
  };

  return (
    <UserProvider value={user}>
      <TweetProvider value={""}>
        <div className="App">
          <Sidebar
            onNotAuthenticated={_handleNotAuthenticated}
            authenticated={authenticated}
          />
          <Main>
            {content}
          <Footer />
            </Main>
        </div>
      </TweetProvider>
    </UserProvider>
  );
};

export default App;

const Main = styled.main`
  width: calc(100% - calc(var(--sidebarWidth) * 0.75));
  margin-left: calc(var(--sidebarWidth) * 0.75);
  min-height: 100vh;
  background: #fff;
  padding: 1em 1em;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: rgb(21, 32, 43);

  @media ${device.laptop} {
    padding: 1em 20vw;
  width: calc(100% - calc(var(--sidebarWidth)));

  margin-left: var(--sidebarWidth);

  }
`;

const Intro = styled.section`
  margin: 0.5rem auto;
  width: 100%;

  @media ${device.laptop}{
    padding: 2rem 0;
    margin: 2rem;
  }
`;
