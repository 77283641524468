import React, { useState, useEffect, useContext } from "react";

import Moment from "react-moment";
import moment from "moment-timezone";

import styled from "styled-components";
import { device } from "../../utils/breakpoints";

import ProfilePicture from "./ProfilePicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TweetContext from "../../TweetContext";
import { Link } from "react-router-dom";

import twitter from "twitter-text";

const Tweet = ({
  tweetId,
  tweet,
  text,
  user,
  tweetUser,
  date,
  draft,
  scheduled,
  quote,
  tweetBoxRef,
  setTweetBoxText,
  setTweetBoxReplyToId,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [state, dispatch] = useContext(TweetContext);

  // console.log(props)

  const { scheduleDraft, sendToDrafts, deleteTweet } = tweetActions(
    deleteConfirm,
    setDeleteConfirm,
    tweetId,
    dispatch
  );

  if (!tweet) {
    tweet = {
      text: text ? text : "",
      user: { ...user },
    };
  }
  if (tweetUser) {
    tweet.user = tweetUser;
  }

  if (!date) {
    date = tweet.created_at;
  }

  let tweet_images = [];

  if (tweet?.entities) {
    if (tweet.entities.media) {
      tweet_images = tweet.entities.media.map((image) => {
        return <img key={image.id} src={image.media_url_https} />;
      });
    }
  }

  let tweet_text = tweet.full_text
    ? tweet.full_text
    : tweet
    ? tweet.text
    : text;
  let tweet_text_string, tweet_text_array;

  if (!Array.isArray(tweet_text)) {
    tweet_text_string = twitter.autoLink(tweet_text, {
      urlEntities: tweet.entities?.urls,
    });
    tweet_text_array = [tweet_text_string];
  } else {
    tweet_text_array = tweet_text.map((tweet_txt) =>
      twitter.autoLink(tweet_txt, { urlEntities: tweet.entities?.urls })
    );
  }

  let tweetTextObj = tweet_text_array.map((text, i) => {
    return (
      <div
        key={i}
        dangerouslySetInnerHTML={{
          __html: text.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        }}
      />
    );
  });

  let dateFormatted =
    date && user
      ? moment.tz(date, user.settings.timezone).format("MM-DD-YYYY @ hh:mm a")
      : "";

  // console.log(tweet);
  const tweetUrl = `https://twitter.com/${tweet?.user?.username ||
    user.username}/status/${tweet.id}`;
  return (
    <TweetWrapper className={quote ? "quote-tweet" : ""}>
      <TweetUserImage>
        <ProfilePicture
          img={tweet?.user?.profile_image_url_https || user.profile_image_url}
        />
      </TweetUserImage>
      <TweetMeta>
        <li className="name">
          <strong>{tweet?.user?.name || user?.name}</strong> @
          {tweet?.user?.username || user?.username}
        </li>
        {!draft && date && (
          <>
            <li className="date">
              <Moment title={dateFormatted} fromNow>
                {date}
              </Moment>
            </li>
          </>
        )}
        <TweetMenu>
          <TweetMenuButton
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
              setDeleteConfirm(false);
            }}
          >
            <FontAwesomeIcon
              icon={isMenuOpen ? "ellipsis-h" : "ellipsis-v"}
              fixedWidth
            />
          </TweetMenuButton>
          {isMenuOpen && (
            <TweetMenuList>
              {draft && (
                <MenuItem onClick={scheduleDraft} className="draft">
                  <button>
                    <FontAwesomeIcon icon="clock" fixedWidth /> Add To Queue
                  </button>
                </MenuItem>
              )}
              {scheduled && (
                <MenuItem onClick={sendToDrafts} className="draft">
                  <button>
                    <FontAwesomeIcon icon="clock" fixedWidth /> Move To Drafts
                  </button>
                </MenuItem>
              )}

              {tweet.id && (
                <MenuItem
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                  className="direct"
                >
                  <a href={tweetUrl} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon="link" fixedWidth /> Direct Link
                  </a>
                </MenuItem>
              )}

              {tweet.id && (
                <MenuItem className="retweet">
                  <button
                    onClick={() => {
                      setTweetBoxText(["\n\n" + tweetUrl]);
                      setIsMenuOpen(false);
                    }}
                  >
                    <FontAwesomeIcon icon="retweet" fixedWidth /> Retweet
                  </button>
                </MenuItem>
              )}

              {tweet.id && (
                <MenuItem className="reply">
                  <button
                    onClick={() => {
                      setTweetBoxText([`@${tweet.user?.user_name} `]);
                      setTweetBoxReplyToId(tweet.id);
                      setIsMenuOpen(false);
                    }}
                  >
                    <FontAwesomeIcon icon="reply" fixedWidth /> Reply
                  </button>
                </MenuItem>
              )}

              {(scheduled || draft) && (
                <MenuItem className="edit">
                  <Link to={"/edit/" + tweetId}>
                    <FontAwesomeIcon icon="pencil-alt" fixedWidth /> Edit Tweet
                  </Link>
                </MenuItem>
              )}
              {(scheduled || draft) && (
                <MenuItem onClick={deleteTweet} className="trash">
                  <button>
                    <FontAwesomeIcon icon="trash" fixedWidth />{" "}
                    {!deleteConfirm ? "Delete" : "Are You Sure?"}
                  </button>
                </MenuItem>
              )}
            </TweetMenuList>
          )}
        </TweetMenu>
      </TweetMeta>

      <TweetBody>
        {tweetTextObj}

        <TweetImages>{tweet_images}</TweetImages>
        {tweet?.is_quote_status && tweet?.quoted_status && (
          <Tweet
            tweet={tweet.quoted_status}
            setTweetBoxText={setTweetBoxText}
            tweetBoxRef={tweetBoxRef}
            setTweetBoxReplyToId={setTweetBoxReplyToId}
            quote
          />
        )}

        {!quote && (
          <TweetStats>
            <li>
              <FontAwesomeIcon icon="comment" fixedWidth />{" "}
              {tweet.public_metrics?.reply_count}
            </li>
            <li>
              <FontAwesomeIcon icon="heart" fixedWidth />{" "}
              {tweet.public_metrics?.like_count}
            </li>
            <li>
              <FontAwesomeIcon icon="retweet" fixedWidth />
              {tweet.public_metrics?.retweet_count}
            </li>
          </TweetStats>
        )}
      </TweetBody>
    </TweetWrapper>
  );
};

export default Tweet;

const TweetWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;

  padding: 0.5rem;
  border-bottom: 1px solid var(--gray);
  width: 100%;
  p {
    line-height: 1.25em;
  }

  &.quote-tweet {
    border: 1px solid var(--gray);
  }
`;

const TweetBody = styled.div`
  padding: 0.25em 1em;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  grid-column: 1 / -1;
  font-size: 1rem;
  overflow: hidden;
  color: #fff;

  @media ${device.laptop} {
    font-size: 1.25rem;
  }

  p {
    margin: 0.5em 0;
    line-height: 1.1em;
  }

  a {
    color: var(--tweetColor);
    text-decoration: none;
    font-weight: 800;

    &:hover {
      color: var(--darkerColor);
    }
  }
`;

const TweetImages = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`;
const TweetUserImage = styled.div`
  .profile-picture {
    max-width: 50px;
  }
`;

const TweetMeta = styled.div`
  margin: 0 0 0.5em;
  font-size: 0.8rem;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: center;
  overflow-wrap: break-word;
  /* color: var(--lighterTextColor); */
  .date {
    display: none;
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media ${device.laptop} {
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .date {
      display: block;
    }
  }

  li {
    list-style: none;
    padding: 0em 0.15em;
    color: var(--textColor);

    strong {
      color: var(--textColor);
    }

    &:last-of-type {
    }

    &.date {
      color: var(--textColor);
    }
  }
`;

const TweetMenu = styled.div`
  display: flex;
  margin-left: auto;
  position: relative;
`;

const TweetMenuButton = styled.button`
  padding: 0.5em;
  color: var(--textColor);
  border: 1px solid var(--borderColor);
  background: none;
  border-radius: var(--borderRadius);

  &:focus {
    border-color: var(--tweetColor);
    border-style: inset;
    outline: none;
  }
  &:hover {
    background: var(--tweetColor);
    color: #fff;
    cursor: pointer;
  }
`;

const TweetMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 50vw;
  width: 100%;
  position: absolute;
  margin: 0;
  transform-origin: 0% 100%;
  transform: translateY(100%);
  bottom: 0;
  right: 0;
  z-index: 999999;

  background: var(--darkestColor);
  /* padding: 1rem 0.25rem; */
  border: 2px solid var(--borderColor);
  box-shadow: var(--boxShadow);
  padding: 0;

  border-radius: var(--borderRadius) 0 var(--borderRadius) var(--borderRadius);

  @media ${device.laptop} {
    min-width: 15vw;
  }
`;

const MenuItem = styled.li`
  width: 100%;
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
  list-style: none;

  button,
  a {
    border: none;
    outline: none;
    font-size: 1em;
    background: none;

    &:hover {
      background: none;
      color: #fff;
    }
    &:first-of-type {
      border-radius: var(--borderRadius) 0 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 var(--borderRadius) var(--borderRadius);
    }
  }
  &:hover {
    background: var(--tweetColor);
    color: #fff;
  }

  a,
  button {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: inherit;
    width: 100%;
    text-decoration: none;

    svg {
      margin-right: auto;
    }
  }

  &.trash {
    color: var(--red);

    button {
      cursor: pointer;
    }

    &:hover {
      background: var(--red);
      color: #fff;
    }
  }
`;

const TweetStats = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  margin: 0.5em 0 0;
  padding: 0.5em;

  list-style: none;

  border-top: 1px dotted var(--borderColor);
  width: 100%;
  li {
    margin: 0 1em 0 0;
    padding: 0;

    svg {
      margin-right: 0.5em;

      &.fa-heart {
        color: red;
      }

      &.fa-retweet {
        color: rgb(23, 191, 99);
      }
    }
  }
`;

function tweetActions(deleteConfirm, setDeleteConfirm, tweetId, dispatch) {
  const deleteTweet = () => {
    if (!deleteConfirm) {
      setDeleteConfirm(true);
      return;
    } else if (deleteConfirm) {
      setDeleteConfirm(false);
      fetch(process.env.REACT_APP_API_URL + "tweet/delete/" + tweetId, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 204) return true;
          throw new Error("failed to authenticate user");
        })
        .then(() => {
          dispatch({ type: "loading" });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const scheduleDraft = async () => {
    await updateTweet({ status: "scheduled" }).then(() => {
      dispatch({ type: "loading" });
    });
  };

  const sendToDrafts = async () => {
    await updateTweet({ status: "draft" }).then(() => {
      dispatch({ type: "loading" });
    });
  };

  const updateTweet = async (settings = {}) => {
    fetch(process.env.REACT_APP_API_URL + "tweet/update/" + tweetId, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({ settings: settings }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 204)
          return response.json();
        throw new Error("failed to authenticate user");
      })
      .catch((error) => {});
  };
  return { scheduleDraft, sendToDrafts, deleteTweet };
}

function formatTweetText(text, tweet) {
  if (tweet?.entities) {
    if (tweet.entities.media) {
      tweet.entities.media.map((image) => {
        text = text.replace(image.url, "");
      });
    }

    if (tweet.entities.urls) {
      tweet.entities.urls.map((url) => {
        text = text.replace(
          url.url,
          `<a href=${url.expanded_url} target="_blank">${url.display_url}</a>`
        );
      });
    }

    if (tweet.entities.hashtags) {
      tweet.entities.hashtags.map((hashtag) => {
        text = text.replace(
          "#" + hashtag.text,
          `<a href=${`https://twitter.com/hashtag/${hashtag.text}`} target="_blank">#${
            hashtag.text
          }</a>`
        );
      });
    }

    if (tweet.entities.user_mentions) {
      tweet.entities.user_mentions.map((user_mention) => {
        text = text.replace(
          "@" + user_mention.screen_name,
          `<a href=${`https://twitter.com/${user_mention.screen_name}`} target="_blank">@${
            user_mention.screen_name
          }</a>`
        );
      });
    }

    if (tweet.entities.symbols) {
      tweet.entities.symbols.map((symbol) => {
        text = text.replace(
          "$" + symbol.text,
          `<a href=${`https://twitter.com/search?q=%24${symbol.text}`} target="_blank">$${
            symbol.text
          }</a>`
        );
      });
    }
  }
  let text_string = "";
  if (text) {
    text = text.split("\n").map((item, i) => {
      return (text_string += `<p key=${i}>${item}</p>`);
    });
  } else {
    text_string = text;
  }

  return text_string;
}
