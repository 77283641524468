import React from "react";

import styled from "styled-components";

const Footer = ({ onClick, loading }) => {
  return (
    <FooterWrapper onClick={onClick} className="Footer">
      <p>
        &copy; 2020-2022{" "}
        <a
          href="https://jackharner.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Jack Harner
        </a>
      </p>
      <p>
        <a
          href={process.env.REACT_APP_TWITTER_CONTACT_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          Feedback / Issues?
        </a>
      </p>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1em;
  margin: 0.25em auto;
  padding: 0.25rem;

  p {
    padding: 0 1em;
    color: #fff;
    

    a {
      color: inherit;
      font-weight: bold;
      text-decoration: none;
    }
  }
`;
