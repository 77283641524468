import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { device } from "../utils/breakpoints";

import TweetContext from "../TweetContext";
import Loader from "./common/loader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TweetInput from "./TweetInput";

import twitter from "twitter-text";

const TweetBox = ({
  tweet,
  tweetId,
  tweetBoxRef,
  tweetBoxText,
  setTweetBoxText,
  tweetBoxReplyToId,
  setTweetBoxReplyToId,
}) => {
  const [tweetText, setTweetText] = useState(tweetBoxText);
  const [tweetCharCount, setTweetCharCount] = useState(0);
  const [tweetCharLimit, setTweetCharLimit] = useState(280);
  const [valid, setValid] = useState(false);
  const [tweeting, setTweeting] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const history = useHistory();

  const [state, dispatch] = useContext(TweetContext);
  useEffect(() => {
    let isCancelled = false;

    // console.log({stateStatus: state.status});
    if (tweetId) {
      fetch(process.env.REACT_APP_API_URL + "tweet/get/" + tweetId, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("failed to authenticate user");
        })
        .then((responseJson) => {
          dispatch({ type: "success" });

          setTweetBoxText(responseJson[0].full_text);
          setTweetCharCount(responseJson[0].full_text.length);
          const isTweetValid = validateTweet(responseJson[0].full_text[0]);
          if (isTweetValid) {
            setValid(true);
          } else {
            setValid(false);
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setError("Failed to authenticate user");
          }
        });

      return function cleanup() {
        isCancelled = true;
      };
    }
  }, []);

  useEffect(() => {
    tweetBoxRef.current = new Array(tweetBoxText.length);
    validateTweet(tweetBoxText || "");

    if (tweetBoxRef.current[0]) {
      tweetBoxRef.current[0].focus();
      if (tweetBoxText === "") {
        tweetBoxRef.current[0].setSelectionRange(0, 0);
      }
    }
  }, [tweetBoxText]);

  const tweetNow = () => {
    setTweeting(true);
    fetch(process.env.REACT_APP_API_URL + "tweet/now", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        text: tweetBoxText,
        in_reply_to_status_id: tweetBoxReplyToId || null,
      }),
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        if (response.status === 400) throw response.text();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        clearTextBox();
        dispatch({ type: "loading" });
      })
      .then(goHome())
      .catch((error) => {
        setTweeting(false);
        setError(true);
        return error;
      })
      .then((error) => {
        setErrorMsg(error);
      });
  };

  const scheduleTweet = () => {
    setTweeting(true);

    fetch(process.env.REACT_APP_API_URL + "tweet/schedule", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        text: tweetBoxText,
        in_reply_to_status_id: tweetBoxReplyToId || null,
      }),
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        clearTextBox();
        dispatch({ type: "loading" });
      })
      .then(goHome())
      .catch((error) => {
        setTweeting(false);
        setError(true);
        return error;
      })
      .then((error) => {
        setErrorMsg(error);
      });
  };

  const draftTweet = () => {
    setTweeting(true);

    fetch(process.env.REACT_APP_API_URL + "tweet/draft", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        text: tweetBoxText,
        in_reply_to_status_id: tweetBoxReplyToId || null,
      }),
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        clearTextBox();
        dispatch({ type: "loading" });
      })
      .then(goHome())
      .catch((error) => {
        setTweeting(false);
        setError(true);
        return error;
      })
      .then((error) => {
        setErrorMsg(error);
      });
  };

  const addTweet = () => {
    setTweetBoxText([...tweetBoxText, ""]);
    setValid(false);
  };

  const removeTweet = (index) => {
    tweetBoxText.splice(index, 1);
    setTweetBoxText(tweetBoxText);
    dispatch({ type: "loading" });
  };

  const clearTextBox = () => {
    setTweetBoxText([""]);
    setValid(false);
    setTweeting(false);
    setTweetCharCount(0);
    setTweetBoxReplyToId(null);
  };

  const goHome = () => {
    history.push("/");
  };

  const updateSchedule = async () => {
    await updateTweet({ text: tweetBoxText, status: "scheduled" })
      .then(() => {
        dispatch({ type: "loading" });
      })
      .then(goHome);
  };

  const updateDraft = async () => {
    await updateTweet({ text: tweetBoxText, status: "draft" })
      .then(() => {
        dispatch({ type: "loading" });
      })
      .then(goHome);
  };

  const updateTweet = async (settings = {}) => {
    fetch(process.env.REACT_APP_API_URL + "tweet/update/" + tweetId, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({ settings: settings }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 204)
          return response.json();
        throw new Error("failed to authenticate user");
      })
      .catch((error) => {});
  };

  const validateTweet = (tweetText) => {
    if (typeof tweetText === "string") {
      let tweetResults = twitter.parseTweet(tweetText);
      console.log({ VALIDATE: tweetResults });
      if (!tweetResults.valid) return false;
      return true;
    } else {
      return false;
    }
  };

  const handleAddEmoji = (threadIndex, emoji) => {
    let cursorPosStart = tweetBoxRef.current[threadIndex].selectionStart;
    let cursorPosEnd = tweetBoxRef.current[threadIndex].selectionEnd;
    let newTweetText =
      tweetBoxText[threadIndex].slice(0, cursorPosStart) +
      emoji +
      tweetBoxText[threadIndex].slice(cursorPosEnd);
    const isTweetValid = validateTweet(newTweetText);
    if (isTweetValid) {
      setValid(true);
      setError(false);
    } else {
      setValid(false);
      setError(true);
    }
    let tweetCharCount = newTweetText.length;
    setTweetCharCount(tweetCharCount);

    tweetBoxText[threadIndex] = newTweetText;
    setTweetBoxText(tweetBoxText);
    tweetBoxRef.current[threadIndex].focus();
  };

  const handleChange = (e, index) => {
    let tweetText = e.target.value;
    let tweetCharCount = tweetText.length;

    const isTweetValid = validateTweet(tweetText);
    if (isTweetValid) {
      setValid(true);
      setError(false);
    } else {
      setValid(false);
      setError(true);
    }
    tweetBoxText[index] = tweetText;
    setTweetBoxText(tweetBoxText);
    setTweetCharCount(tweetCharCount);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) {
      scheduleTweet();
    }
  };
  const onCancel = (e) => {
    clearTextBox();
    goHome();
  };

  const onKeyUp = (e, index) => {
    console.log(tweetBoxRef);
    var scrollHeight = tweetBoxRef.current[index].scrollHeight,
      height = tweetBoxRef.current[index].offsetHeight;
    if (tweetBoxRef.current[index].value === "") {
      tweetBoxRef.current[index].style.removeProperty("height");
    } else if (scrollHeight > height) {
      tweetBoxRef.current[index].style.height = scrollHeight + "px";
    }
  };

  let inputs = tweetBoxText.map((text, i) => {
    return (
      <TweetInput
        tweetBoxReplyToId={tweetBoxReplyToId}
        tweetBoxText={text}
        setTweetBoxText={setTweetBoxText}
        tweetBoxRef={tweetBoxRef}
        threadIndex={i}
        key={i}
        handleChange={(e) => {
          handleChange(e, i);
        }}
        handleAddEmoji={(e) => {
          handleAddEmoji(i, e.target.value);
        }}
        onKeyDown={(e) => {
          onKeyDown(e, i);
        }}
        onKeyUp={(e) => {
          onKeyUp(e, i);
        }}
        removeTweet={() => {
          removeTweet(i);
        }}
      />
    );
  });

  return (
    <TweetBoxWrapper>
      <TweetBoxHeader>
        <h2>Schedule a Tweet</h2>
        {error && (
          <TweetBoxError>{errorMsg || "Something Went Wrong"}</TweetBoxError>
        )}
      </TweetBoxHeader>
      {inputs}
      <Buttons>
        {tweetId && (
          <button className="alt" onClick={onCancel} disabled={tweeting}>
            {tweeting ? <Loader /> : "cancel"}
          </button>
        )}

        <button
          className="alt"
          onClick={addTweet}
          disabled={!valid || tweeting}
        >
          {tweeting ? <Loader /> : "Add Tweet"}
        </button>
        <button
          className="alt"
          onClick={tweetId ? updateDraft : draftTweet}
          disabled={!valid || tweeting}
        >
          {tweeting ? <Loader /> : "save draft"}
        </button>
        <button
          className="alt"
          onClick={tweetNow}
          disabled={!valid || tweeting}
        >
          {tweeting ? <Loader /> : "tweet now"}
        </button>
        <button
          className="main"
          onClick={tweetId ? updateSchedule : scheduleTweet}
          disabled={!valid || tweeting}
        >
          {tweeting ? <Loader /> : "Add To Queue"}
        </button>
      </Buttons>
    </TweetBoxWrapper>
  );
};

export default TweetBox;

const TweetBoxWrapper = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const TweetBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
`;

const TweetBoxError = styled.h3`
  text-align: center;
  color: red;
`;

const Buttons = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  button {
    border-radius: 5px;
    padding: 0.5em 1em;

    cursor: pointer;
    text-decoration: lowercase;
    color: #fff;

    &.main {
      background: var(--tweetColor);
      font-weight: 900;
      border: 2px solid var(--tweetColor);
    }

    &.alt {
      background: none;
      border: 2px solid var(--darkerColor);
      color: var(--darkerColor);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .loader {
      font-size: 1em;
    }
  }
`;
