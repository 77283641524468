import React, { useState, useContext } from "react";

import styled, { keyframes } from "styled-components";
import { device } from "../../utils/breakpoints";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import UserContext from "../../UserContext";

import logo from "../../assets/img/simple-tweets-logo--white.svg";

const Sidebar = ({ profileImg, authenticated }) => {
  const [menuOpen, setMenuOpen] = useState();

  const user = useContext(UserContext);

  const _handleLogoutClick = () => {
    // Logout using Twitter passport api
    // Set authenticated state to false in the HomePage
    window.open(process.env.REACT_APP_API_URL + "auth/logout", "_self");
    this.props.onNotAuthenticated();
  };

  return (
    <>
      <StyledSidebar open={menuOpen}>
        <Link
          to="/"
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <Logo>
            <img src={logo} />
          </Logo>
        </Link>
        {authenticated && (
          <>
            <Menu className={menuOpen ? "open" : "closed"}>
              <ul>
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  >
                    <span className="label">Home</span>
                    <FontAwesomeIcon icon="home" />
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                    to={"/threads"}
                  >
                    <span className="label">Pinned Threads</span>
                    <FontAwesomeIcon icon="thumbtack" />
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                    to={"/analytics"}
                  >
                    <span className="label">Analytics</span>
                    <FontAwesomeIcon icon="chart-pie" />
                  </Link>
                </li>

                <li>
                  <Link
                    to="/settings"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  >
                    <span className="label">Settings</span>
                    <FontAwesomeIcon icon="cog" />
                  </Link>
                </li>
                <li>
                  <a
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                    href={process.env.REACT_APP_TWITTER_CONTACT_LINK}
                    target="_blank"
                    rel="noopener norefferer"
                  >
                    <span className="label">Feedback</span>
                    <FontAwesomeIcon icon="question" />
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                    href={process.env.REACT_APP_API_URL + "auth/logout"}
                  >
                    <span className="label">Logout</span>
                    <FontAwesomeIcon icon="sign-out-alt" />
                  </a>
                </li>
              </ul>
            </Menu>

            <ProfilePicture
              img={user ? user.profile_image_url : ""}
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            />
          </>
        )}
      </StyledSidebar>

      {menuOpen && (
        <SidebarBG
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        />
      )}
    </>
  );
};

export default Sidebar;

const StyledSidebar = styled.div`
  width: ${(props) => (props.open ? "50vw" : "calc(var(--sidebarWidth) * 0.75)")};
  height: 100vh;
  position: fixed;
  background: var(--tweetColor);
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 20;
  transition: 300ms;

  @media ${device.laptop} {
    width: ${(props) => (props.open ? "10vw" : "var(--sidebarWidth)")};
  }

  .profile-picture {
    max-width: 50px;
  }
`;

const slideIn = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

const SidebarBG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  animation: ${slideIn} 400ms;

  z-index: 15;
`;

const Logo = styled.div`
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: auto;
  img {
    width: 100%;
    max-width: 25vw;
    @media ${device.laptop} {
      width:100%;
      max-width: 100px;
    }
    
  }
`;

const Menu = styled.nav`
  margin-top: auto;
  width: 100%;

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  ul {
    flex-direction: column;
  }

  li {
    border-top: 1px solid rgba(255, 255, 255, 0.5);

    &:last-of-type {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  a {
    padding: 1em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    text-decoration: none;

    &:hover {
      background: rgba(255, 255, 255, 1);
      color: var(--tweetColor);
    }
  }

  &.closed {
    .label {
      display: none;
    }
    svg {
      margin: auto;
    }
  }
`;
