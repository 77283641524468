import { library, config } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCog,
  faCoffee,
  faSignOutAlt,
  faHome,
  faHeart,
  faRetweet,
  faSync,
  faEllipsisH,
  faEllipsisV,
  faTrash,
  faClock,
  faChartPie,
  faQuestion,
  faPencilAlt,
  faLink,
  faThumbtack,
  faComment, faReply
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faCog,
  faCoffee,
  faSignOutAlt,
  faHome,
  faHeart,
  faRetweet,
  faSync,
  faEllipsisH,
  faEllipsisV,
  faTrash,
  faClock,
  faChartPie,
  faQuestion,
  faPencilAlt,
  faLink,
  faThumbtack,
  faComment, faReply);
