import React, { useState, useEffect, useContext, useRef } from "react";
import S3 from "react-aws-s3";
import styled from "styled-components";
import TweetContext from "../TweetContext";
import Loader from "./common/loader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { device } from "../utils/breakpoints";

require("dotenv").config();

const TweetInput = ({
  tweet,
  tweetId,
  tweetBoxRef,
  tweetBoxText,
  setTweetBoxText,
  tweetBoxReplyToId,
  threadIndex,
  handleChange,
  handleAddEmoji,
  onKeyDown,
  onKeyUp,
  removeTweet,
}) => {
  const [tweetCharLimit, setTweetCharLimit] = useState(280);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const fileRef = useRef();

  const [state, dispatch] = useContext(TweetContext);

  const handleInput = (e) => {
    e.preventDefault();
    let file = fileRef.current.files[0];
    let newFileName = fileRef.current.files[0].name;

    let config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName)
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    let isCancelled = false;

    // console.log({stateStatus: state.status});
    if (tweetId) {
      fetch(process.env.REACT_APP_API_URL + "tweet/get/" + tweetId, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("failed to authenticate user");
        })
        .then((responseJson) => {
          dispatch({ type: "success" });

          setTweetBoxText(responseJson[0].full_text[0]);
          const isTweetValid = validateTweet(responseJson[0].full_text);
          if (isTweetValid) {
            setValid(true);
          } else {
            setValid(false);
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setError("Failed to authenticate user");
          }
        });

      return function cleanup() {
        isCancelled = true;
      };
    }
  }, []);

  useEffect(() => {
    validateTweet(tweetBoxText || "");
  }, [tweetBoxText]);

  const validateTweet = (tweetText) => {
    let tweetCharCount = tweetText.length;

    if (tweetCharCount <= 0) return false;
    if (tweetCharCount > tweetCharLimit) return false;

    return true;
  };

  return (
    <TweetInputWrapper>
      <TweetInputHeader>
        {threadIndex > 0 ? <button onClick={removeTweet}>X</button> : ""}
        {tweetBoxText.length > tweetCharLimit * 0.75 ? (
          <p
            className={
              (tweetBoxText.length <= tweetCharLimit ? "valid" : "invalid") +
              " tweet-char-limit " +
              (tweetBoxText.length >= tweetCharLimit * 0.9 ? "close" : "")
            }
          >
            {tweetBoxText.length}/{tweetCharLimit}
          </p>
        ) : (
          ""
        )}
        {tweetBoxReplyToId && <p>In reply to: {tweetBoxReplyToId}</p>}
      </TweetInputHeader>
      <TextAreaWrapper
        onClick={(e) => {
          setIsOpen(true);
        }}
      >
        <textarea
          required
          className={isOpen ? "is-open" : ""}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          value={tweetBoxText}
          placeholder="What's On Your Mind?"
          ref={(el) => {
            if (tweetBoxRef.current) {
              tweetBoxRef.current[threadIndex] = el;
            }
          }}
        />
        {isOpen && (
          <TweetInputHeader>
            {/* {<input type="file" ref={fileRef} onChange={handleInput} />} */}

            <EmojiPicker className="emoji-picker">
              {[
                "😄",
                "🙃",
                "😅",
                "😂",
                "😭",
                "🙈",
                "🚀",
                "💸",
                "👇🏽",
                "1️⃣",
                "2️⃣",
                "3️⃣",
                "4️⃣",
                "🧵",
                "☑️",
                "❌",
              ].map((emoji) => {
                return (
                  <button onClick={handleAddEmoji} value={emoji}>
                    {emoji}
                  </button>
                );
              })}
            </EmojiPicker>
          </TweetInputHeader>
        )}
      </TextAreaWrapper>
    </TweetInputWrapper>
  );
};

export default TweetInput;

const TweetInputWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const TweetInputHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tweet-char-limit {
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
    transition: 300ms;
    border-radius: 5px;
    border: 1px solid var(--gray);
    padding: 0.25em;
    &.close {
      background: #ffeb3b;
      color: var(--darkGray);
    }
    &.invalid {
      background: var(--red);
      font-weight: 900;
      color: #fff;
    }
  }
`;

const TextAreaWrapper = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  background: var(--darkestColor);
  border-radius: 5px;
  border: 1px solid var(--gray);
  textarea {
    font-family: inherit;
    background: rgba(255, 255, 255, 0.1);
    grid-column: 1 / -1;
    font-size: 1.5rem;
    height: 2.1em;
    line-height: 1em;
    transition: 300ms;
    border: none;
    border-radius: 5px;
    resize: none;
    color: #fff;
    padding: 0.5em;
    &.is-open {
      height: 5em;
    }
  }
`;

const EmojiPicker = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media ${device.laptop} {
    grid-template-columns: repeat(auto-fill, minmax(6.25%, 1fr));
  }

  button {
    margin: 0.5rem;
    padding: 0.5rem;
    border: none;
    background: var(--darkerColor);
    line-height: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: 100ms;
    &:hover {
      background: var(--tweetColor);
      cursor: pointer;
    }
    &:active {
      opacity: 0.5;
      transform: scale(0.9);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 0.5em;
    border-radius: 5px;
    padding: 0.5em 1em;

    cursor: pointer;
    color: #fff;

    &.main {
      background: var(--tweetColor);
      font-weight: 900;
      border: 2px solid var(--tweetColor);
    }

    &.alt {
      background: #fff;
      border: 2px solid var(--darkerColor);
      color: var(--darkerColor);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .loader {
      font-size: 1em;
    }
  }
`;
