import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import Loader from "./common/loader";
import Tweet from "./common/Tweet";
import UserContext from "../UserContext";
import TweetContext from "../TweetContext";

import moment from "moment-timezone";
import { device } from "../utils/breakpoints";

const ScheduledTweets = () => {
  const user = useContext(UserContext);
  const [state, dispatch] = useContext(TweetContext);
  const [scheduledTweets, setScheduledTweets] = useState([]);
  const [windowFocus, setWindowFocus] = useState(true);


  useEffect(() => {


    let autoRefresh = null;
    var focused = true;

    if (focused) {
      autoRefresh = setInterval(() => {
        // console.log("dispatch Here");
        dispatch({ type: "loading" });
      }, 15 * 1000);

      fetch(process.env.REACT_APP_API_URL + "tweet/scheduled", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("failed to authenticate user");
        })
        .then((responseJson) => {
          dispatch({ type: "success" });
          setScheduledTweets(responseJson);
  
          console.log(responseJson)
        })
        .catch((error) => {
          return { error: error };
        });

    } else {
      clearInterval(autoRefresh);
    }


    window.onfocus = function() {
      focused = true;
      dispatch({ type: "loading" });

      console.log('focused');

    };
    window.onblur = function() {
      focused = false;
      console.log('blur');
      clearInterval(autoRefresh);

    };
    return () => {
      clearInterval(autoRefresh);
    };
  }, [state.status, state.scheduledTweets]);

  const tweets =
    scheduledTweets.length > 0 ? (
      scheduledTweets.map((tweet, i) => (
        <Tweet
          tweetId={tweet._id}
          scheduled
          tweet={tweet}
          date={user.nextTweet ? user.nextTweet[i] : null}
          key={tweet._id}
          user={user}
        />
      ))
    ) : state.status === "loading" || state.status === null ? (
      <NothingScheduled>Loading.</NothingScheduled>
    ) : (
      <NothingScheduled>Nothing Scheduled.</NothingScheduled>
    );

  return (
    <Intro>
      <h1>
        Tweet Queue
        <Loader
          loading={state.status === "loading"}
          onClick={() => {
            dispatch({ type: "loading" });
          }}
        />
      </h1>
      <h3>{scheduledTweets.length} Tweets Scheduled</h3>
      {user.nextTweet ? (
        <h4>
          Next Tweet at{" "}
          {moment
            .tz(user.nextTweet[0], user.settings.timezone)
            .format("h:mm a")}
        </h4>
      ) : (
        <h4>No Times Selected</h4>
      )}

      <TweetList>{tweets}</TweetList>
    </Intro>
  );
};

export default ScheduledTweets;

const Intro = styled.section`
  padding: 2rem 0;
  margin: 2rem;
  width: 100%;
  position: relative;

  .loader {
    font-size: 1rem;
    width: auto;
    display: inline-flex;
    margin: 0 0.5rem;
    color: #fff;
  }
`;

const NothingScheduled = styled.h2`
  font-weight: 400;
  text-align: center;
  margin: 1em;
  color: #fff;
  font-size: 1.25rem;

  @media ${device.laptop}{
  margin: 3em;

  }
`;

const TweetList = styled.section`
  border: 1px solid var(--gray);
  margin: 1em auto;
  max-width: 800px;
`;
