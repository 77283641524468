import React from "react";

import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import logo from "../../assets/img/simple-tweets-logo--black.svg"

const LoaderPage = ({onClick, loading}) => {


    return(<LoaderPageWrapper onClick={onClick} className="loaderPage"><LoaderImg src={logo} /></LoaderPageWrapper>)

}


export default LoaderPage


const LoaderPageWrapper = styled.div`

width: 100%;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;

font-size: 1em;
margin: 0.25em auto;
padding: 0.25rem;
`

const LoaderImg = styled.img`

max-width: 50%;
filter: invert(1);
opacity: 0.2;

`