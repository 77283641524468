import React from "react";

import styled from "styled-components"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Loader = ({onClick, loading}) => {


    return(<LoaderWrapper onClick={onClick} className="loader"><FontAwesomeIcon icon="sync" spin={loading} /></LoaderWrapper>)

}


export default Loader


const LoaderWrapper = styled.div`

width: 100%;
display: flex;
align-items: center;
justify-content: center;

font-size: 1em;
margin: 0.25em auto;
padding: 0.25rem;
`