import React, { useContext } from "react";
import styled from "styled-components";
import ProfilePicture from "../common/ProfilePicture";
import UserContext from "../../UserContext";

const UserCard = () => {

  const user = useContext(UserContext)


  return (
    <UserCardWrapper>
    <ProfilePicture img={user && user.profile_image_url.replace("_normal", "")} />
      <h1>{user && user.name}</h1>
      <h3>@{user && user.screen_name}</h3>
    </UserCardWrapper>
  );
};

export default UserCard;

const UserCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
    grid-gap: 0.5em;

  padding: 1em;
  margin: 1em auto;
  box-shadow: var(--boxShadow);

  border-radius: var(--borderRadius);
  border: 1px solid rgba(0, 0, 0, 0.2);
  

  width: 100%;

  .profile-picture{
      grid-row: 1 / span 2;
      margin: 1em auto;
      width: 50%; 
  }

  h1,h2{
      margin: 0;
  }
`;

