import React, { useReducer } from "react";

const TweetContext = React.createContext();

export const TweetProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tweetReducer, {
    // initial state
    status: null
  });

  return (
    <TweetContext.Provider value={[state, dispatch]}>
      {children}
    </TweetContext.Provider>
  );
};
export const TweetConsumer = TweetContext.Consumer;

export default TweetContext;

function tweetReducer(state, action) {
  switch (action.type) {
    case "loading":
        return {...state, status: "loading"}
    case "success":
        return {...state, status: "success"}
    
    case "logout":
      return { user: {}, expiresAt: null };
    default:
      return state;
  }
}
