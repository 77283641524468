import React, { Component } from "react";
import axios from "axios";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

require("dotenv").config();

const TwitterSignIn = ({ notYet }) => {
  const onClick = () => {
    window.location.replace(process.env.REACT_APP_API_URL + "auth/twitter");
  };
  const twitterContactLink = process.env.REACT_APP_TWITTER_CONTACT_LINK;
  let error, errorMsg;

  if (notYet == true) {
    errorMsg = (
      <ErrorMessage
        href={twitterContactLink + encodeURIComponent("LET ME INNNN")}
      >
        <h2>Sorry.</h2>
        <p>This is a Closed Beta and You're Not On the List.</p>
        <br />
        <span>Shoot Me A DM With Your Best GIF and I'll Think About It.</span>
      </ErrorMessage>
    );
  }
  return (
    <TwitterSignInWrapper>
      {errorMsg ? (
        <>{errorMsg}</>
      ) : (
        <TwitterSignInHeader>
          <FontAwesomeIcon className="huge" icon={["fab", "twitter"]} />
          <h1>Welcome To SimpleTweets</h1>
          <Buttons>
            <a onClick={onClick} className="main">
              <FontAwesomeIcon icon={["fab", "twitter"]} />
              Sign In With Twitter
            </a>
          </Buttons>
        </TwitterSignInHeader>
      )}
    </TwitterSignInWrapper>
  );
};

export default TwitterSignIn;

const TwitterSignInWrapper = styled.div`
  width: 80%;
  min-height: 20vh;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TwitterSignInHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg.huge {
    font-size: 4rem;
    margin: 0.5em;
    transition: 300ms;
    will-change: transform;
    color: #fff;
  }

  h1 {
    margin: 0 auto 0.5em;
  }

  &:hover {
    svg.huge {
      color: var(--tweetColor);
      transform: scale(1.1);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    margin-left: 0.5em;
    border-radius: 5px;
    padding: 0.5em 1em;

    transition: 300ms;
    cursor: pointer;
    color: #fff;
    svg {
      color: #fff;
      margin-right: 0.5rem;
    }

    &.main {
      background: var(--tweetColor);
      font-weight: 900;
      border: 2px solid var(--tweetColor);
    }

    &.alt {
      background: #fff;
      border: 2px solid var(--darkerColor);
      color: var(--darkerColor);
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &:hover {
      color: #fff;
      background: none;

      svg {
        transform: scale(1);
        color: #fff;
      }
    }
  }
`;

const ErrorMessage = styled.a`
  background: #f44336;
  color: #fff;
  padding: 1rem;
  text-decoration: none;
  font-size: 1.5rem;
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 300ms;
  top: 0;
  box-shadow: var(--boxShadow1);

  span {
    text-decoration: underline;
  }
  h2,
  p {
    color: #fff;
    margin: 0.25rem;
  }

  &:hover {
    top: -10px;
    box-shadow: var(--boxShadow);
  }
`;
