import React, { useState, useEffect, useContext } from "react";
import UserCard from "../components/users/UserCard";
import styled from "styled-components";

import UserContext from "../UserContext";
import Scheduler from "../components/settings/Scheduler";
import { device } from "../utils/breakpoints";

const moment = require('moment-timezone');

const Settings = ({ userProp }) => {
  const user = useContext(UserContext);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [settings, setSettings] = useState(user.settings);


  const handleChange = (e) => {
    let name = e.target.name;
   


    setSettings({...settings, [name]: e.target.value})
      console.log({settings})
      

  };

  const handleCheckboxChange = (e) => {
    let name = e.target.name;

    setSettings((prevState) => ({...settings, [name]: !prevState[name]}))
  }

  const handleScheduleChange = (e, index) => {
    let settingsObj= {...settings};

    console.log(index);

    if(e.target.value != '') {settingsObj.schedule[index] = e.target.value;} else {settingsObj.schedule.splice(index, 1)}


    setSettings(settingsObj)
    console.log(settingsObj);

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log({submitting: {...settings}});



    fetch(process.env.REACT_APP_API_URL + "user/update", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(settings),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        setSettings({...settings})

        window.location = "/";

        console.log(responseJson)
      })
      .catch((error) => {
        return { error: error };
      });
  };

  return (
    <Main>
      {user && !loading && (
        <LoggedIn>
          <h3>Logged in as: </h3>
          <UserCard user={user} />
          <a href={process.env.REACT_APP_API_URL + "auth/logout"}>Not {user.name}?</a>
        </LoggedIn>
      )}
      <SettingsForm onSubmit={handleSubmit} name="settings">
        <h1>Settings</h1>

        <label htmlFor="timezone">
          <h3>What Timezone?</h3>
          <select id="timezone" name="timezone" onChange={handleChange}
            value={settings.timezone}>

          {moment.tz.zonesForCountry("US", {offset: true}).map((tz)=>{return <option key={tz.name} value={tz.name}>{tz.name}</option>})}

          </select>

        </label>


        <label htmlFor="scheduleOrRandom">
          <h3>On A Schedule or Random?</h3>
        </label>

        <div className="switch-field" id="scheduleOrRandom" >
          <input
            type="radio"
            id="switch_left"
            name="scheduleOrRandom"
            onChange={handleChange}
            value="schedule"
            checked={settings.scheduleOrRandom === "schedule"}
          />
          <label htmlFor="switch_left">Schedule</label>
          <input
            type="radio"
            id="switch_right"
            name="scheduleOrRandom"
            value="random"
            onChange={handleChange}
            checked={settings.scheduleOrRandom === "random"}

          />
          <label htmlFor="switch_right">Random</label>
        </div>

        {settings.scheduleOrRandom === "schedule" ? <Scheduler schedules={settings.schedule} timesPerDay={settings.timesPerDay} onChange={handleScheduleChange} /> : ""}
        {settings.scheduleOrRandom === "random" ? <label htmlFor="timesPerDay">
          <h3>Max Times Per Day to Tweet?</h3>
          <input
            type="number"
            value={settings.timesPerDay}
            onChange={handleChange}
            name="timesPerDay"
            id="timesPerDay"
          />
        </label> : ""}
        <h2>Notifications</h2>
        <label htmlFor="bugEmpty">
          <h3>Bug You When You're Queue Is Empty?</h3>
          <input
            type="checkbox"
            onChange={handleCheckboxChange}
            name="bugEmpty"
            id="bugEmpty"
            checked={settings.bugEmpty}
          />
        </label>
        <button type="submit" value="Submit">
          Save Settings
        </button>

        <DangerZone>
          <h1>Danger Zone!</h1>

          <h4>No Turning Back</h4>
          <p>
            <a>Unschedule Every Tweet &raquo;</a>
          </p>
          <p>
            <a>Permanently Delete Your SimpleTweets Account &raquo;</a>
          </p>
        </DangerZone>
      </SettingsForm>
    </Main>
  );
};

export default Settings;

const Main = styled.main`
  max-width: 800px;

  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  grid-gap: 2em;
  margin: auto;

  @media ${device.laptop}{
  grid-template-columns: 1fr 2fr;

  }
`;

const LoggedIn = styled.div`
  width: 100%;
  margin: 1em auto;
  text-align: center;
  display: block;



  @media ${device.laptop}{
    position: sticky;
  top: 1em;

  }
  h3 {
    font-weight: 400;
    font-style: italic;
  }

  a {
    color: #999;
  }
`;

const SettingsForm = styled.form`
  display: flex;
  flex-direction: column;

  input, select {
    margin: 0.5em 0;
    font-size: 1.5em;
    width: 100%;
  }
  label {
    margin: 1em 0;
  }

  button[type="submit"] {
    font-size: 1.25em;
    padding: 0.5em;
    background: var(--tweetColor);
    border: none;
    box-shadow: var(--boxShadow);
    color: #fff;
    border-radius: var(--borderRadius);
    margin-bottom: 2em;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    display: inline-block;
    width: 50%;
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: var(--boxShadow);

    transition: all 0.1s ease-in-out;

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }

  .switch-field label:hover {
    cursor: pointer;
  }

  .switch-field input:checked + label {
    background-color: var(--tweetColor);
    color: #fff;
    font-weight: 900;
  }
`;

const DangerZone = styled.div`
  border: 6px solid var(--red);
  background: #fff;
  width: 100%;
  margin: 1em auto;
  padding: 1em;
  box-shadow: var(--boxShadow);
  border-radius: var(--borderRadius);

  h1,
  h4 {
    color: var(--red);
  }

  & *:last-child {
    margin-bottom: 0;
  }
`;
